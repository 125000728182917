export default {
    // "URL": "https://dev-api.sety.io/api/v1/",
    "URL": "https://api.sety.io/api/v1/",   
    
    "SOCKET": "wss://api2.sety.io/",
    
    "ABLY_KEY": "xpeAQg.MHKexA:FghByx0QiaRsTfgYkr_APUp4Ke4t3WWLb-ih4XY1eGg",
    "ABLY_CHANNEL_NAME":"sety_dev",
    

    "google_map_api_key": "AIzaSyCq3ktbaZz2tGgZUYvyoXIvzoCYb8Ndi50",

    "MAX_LENGTH": 20, 

    "last_refresh": "last_refresh", 

    "danger_status_array": ['pending', 'inactive', 'active', 'completed'], //inactive

    "mapStyle": [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#242f3e"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#242f3e"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#263c3f"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6b9a76"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#38414e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#212a37"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9ca5b3"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#1f2835"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#f3d19c"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2f3948"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#515c6d"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        }
    ], 

    "industries": [
      "Accounting",
      "Airlines/Aviation",
      "Alternative Dispute Resolution",
      "Alternative Medicine",
      "Animation",
      "Apparel & Fashion",
      "Architecture & Planning",
      "Arts & Crafts",
      "Automotive",
      "Aviation & Aerospace",
      "Banking",
      "Biotechnology",
      "Broadcast Media",
      "Building Materials",
      "Business Supplies & Equipment",
      "Capital Markets",
      "Chemicals",
      "Civic & Social Organization",
      "Civil Engineering",
      "Commercial Real Estate",
      "Computer & Network Security",
      "Computer Games",
      "Computer Hardware",
      "Computer Networking",
      "Computer Software",
      "Construction",
      "Consumer Electronics",
      "Consumer Goods",
      "Consumer Services",
      "Cosmetics",
      "Dairy",
      "Defense & Space",
      "Design",
      "Education Management",
      "E-learning",
      "Electrical & Electronic Manufacturing",
      "Entertainment",
      "Environmental Services",
      "Events Services",
      "Executive Office",
      "Facilities Services",
      "Farming",
      "Financial Services",
      "Fine Art",
      "Fishery",
      "Food & Beverages",
      "Food Production",
      "Fundraising",
      "Furniture",
      "Gambling & Casinos",
      "Glass, Ceramics & Concrete",
      "Government Administration",
      "Government Relations",
      "Graphic Design",
      "Health, Wellness & Fitness",
      "Higher Education",
      "Hospital & Health Care",
      "Hospitality",
      "Human Resources",
      "Import & Export",
      "Individual & Family Services",
      "Industrial Automation",
      "Information Services",
      "Information Technology & Services",
      "Insurance",
      "International Affairs",
      "International Trade & Development",
      "Internet",
      "Investment Banking/Venture",
      "Investment Management",
      "Judiciary",
      "Law Enforcement",
      "Law Practice",
      "Legal Services",
      "Legislative Office",
      "Leisure & Travel",
      "Libraries",
      "Logistics & Supply Chain",
      "Luxury Goods & Jewelry",
      "Machinery",
      "Management Consulting",
      "Maritime",
      "Marketing & Advertising",
      "Market Research",
      "Mechanical or Industrial Engineering",
      "Media Production",
      "Medical Device",
      "Medical Practice",
      "Mental Health Care",
      "Military",
      "Mining & Metals",
      "Motion Pictures & Film",
      "Museums & Institutions",
      "Music",
      "Nanotechnology",
      "Newspapers",
      "Nonprofit Organization Management",
      "Oil & Energy",
      "Online Publishing",
      "Outsourcing/Offshoring",
      "Package/Freight Delivery",
      "Packaging & Containers",
      "Paper & Forest Products",
      "Performing Arts",
      "Pharmaceuticals",
      "Philanthropy",
      "Photography",
      "Plastics",
      "Political Organization",
      "Primary/Secondary",
      "Printing",
      "Professional Training",
      "Program Development",
      "Public Policy",
      "Public Relations",
      "Public Safety",
      "Publishing",
      "Railroad Manufacture",
      "Ranching",
      "Real Estate",
      "Recreational",
      "Facilities & Services",
      "Religious Institutions",
      "Renewables & Environment",
      "Research",
      "Restaurants",
      "Retail",
      "Security & Investigations",
      "Semiconductors",
      "Shipbuilding",
      "Sporting Goods",
      "Sports",
      "Staffing & Recruiting",
      "Supermarkets",
      "Telecommunications",
      "Textiles",
      "Think Tanks",
      "Tobacco",
      "Translation & Localization",
      "Transportation/Trucking/Railroad",
      "Utilities",
      "Venture Capital",
      "Veterinary",
      "Warehousing",
      "Wholesale",
      "Wine & Spirits",
      "Wireless",
      "Writing & Editing"
    ]

}
  