import React, { useEffect, useMemo, useRef, forwardRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Table } from "../../Incidents/pages/database";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {KTUtil} from "../../../../_metronic/_assets/js/components/util";
import {useHtmlClassService} from "../../../../_metronic/layout";
import { StatsWidget11 } from "../../../../_metronic/_partials/widgets/stats/StatsWidget11";
// import usersData from './database.json';
import { useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import {Incident_Class_Drop} from "./dropdowns/class";
import {Filter_Class_Drop} from "./dropdowns/filter";
import moment from "moment";
import {CustomersFilter} from "../../Incidents/pages/filter"

import * as actions from "../../Incidents/_redux/incidents/Actions";


import Papa from "papaparse";
import { saveAs } from 'file-saver';
import ReactToPrint from 'react-to-print';

var usersData = require('./databasedd.json');
var allD = require('./data.json');



export function Report(props) {
    const uiService = useHtmlClassService();
    const tabRef = useRef();
    const dispatch = useDispatch();
   
    const { incident_class, org, entities, report_data } = useSelector(
        (state) => ({
            org: state.auth.organization,
            incident_class: state.alert.incident_class,
            report_data: state.alert.report_data,
            entities: state.alert.entities
        }),
        shallowEqual
    );


    const TAB_ANAL_VIEW = [
        {
            url: "/media/svg/icons/Home/Library.svg",
            symbol: 'svg-icon-inf',
            textS: 'symbol-light-info'
        },
        {
            url: "/media/svg/icons/Code/Done-circle.svg",
            symbol: 'svg-icon-success',
            textS: "symbol-light-success"
        },
        {
            url: "/media/svg/icons/Code/Done-circle.svg",
            symbol: 'svg-icon-success',
            textS: "symbol-light-success"
        },
        {
            url: "/media/svg/icons/Code/Done-circle.svg",
            symbol: 'svg-icon-warning',
            textS: "symbol-light-warning"
        }
    ]

    const layoutProps = useMemo(() => {
        return {
          colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
          colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
          colorsThemeBaseSuccess: objectPath.get(
              uiService.config,
              "js.colors.theme.base.success"
          ),
          colorsThemeLightSuccess: objectPath.get(
              uiService.config,
              "js.colors.theme.light.success"
          ),
          fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
      }, [uiService]);
    


    useEffect(() => {
        var series = [];
        var totals = [];
    
        if (report_data) {
            const element = document.getElementById("kt_mixed_widget_14_chart");
            if (!element) {
              return;
            }

            for(var o in report_data.data) {
                series.push(report_data.data[o].category_class ? report_data.data[o].category_class : 'Uncategorized');
                totals.push(report_data.data[o].total);
            }
            series.push('Total Dispatch');
            totals.push(`${report_data ? report_data.total_dispatch : '0'}`);


            const height = parseInt(KTUtil.css(element, 'height'));
            const options = getChartOptions(layoutProps, height, series, totals);
        
            const chart = new ApexCharts(element, options);
            chart.render();
            return function cleanUp() {
              chart.destroy();
            };
        }
        
      }, [layoutProps, report_data]);

      

    useEffect(() => {
        if (!incident_class) {
            dispatch(actions.fetchAllData('incident_class', org.business._id ))
            dispatch(actions.fetchAllData('report_data', org.business._id ))
        }
    }, [incident_class])


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function chunkArray(arrd, size) {
        var data = {category_class: "Total Dispatch", total: `${report_data ? report_data.total_dispatch : '0'}`};
        var arr = [...arrd, data]

        var groupedArray = [];
        for(var i = 0; i < arr.length; i += size) {
          groupedArray.push(arr.slice(i, i+size));
        }
        return groupedArray ;
    }


    function exportToCsv() {

        var un_items = entities;
        var items = [];
        un_items.forEach(element => {
            var da = {};
            da['Incident Location'] = element.input_address ? element.input_address : element.addresses[0].formatted_address;
            da['Time of activation'] = moment(new Date(element.created_at)).format('MMMM Do YYYY, h:mm:ss a')
            da['Requester\'s Name'] = `${capitalizeFirstLetter(element.user ? element.user.first_name : '')} ${capitalizeFirstLetter(element.user ? element.user.last_name : '')}`;
            da['Phone Number'] = `${element.user ? element.user.phone_number : ''}`;
            da['Incident Category'] = `${element.category ? element.category.name.toUpperCase() : 'TBD'}`;
            da['Incident Details'] = `${element.meta_data.more_info ? element.meta_data.more_info: 'NULL'}`;
            da['Incident Feedback'] = `${element.agent_feedback ? element.agent_feedback: ''}`;
            da['Incident Class'] = `${element.category_class ? element.category_class.toUpperCase() : 'NULL'}`;
            da['Incident Status'] = `${element.status ? element.status.toUpperCase() : 'NULL'}`;
            da['Response Time'] = `${element.completed_at ? element.completed_at : 'NULL'}`;
            items.push(da);
        });

        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        const csv = [
        header.join(','),
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')


        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'export.csv');

        
    }


    function exportToCsv2() {

        var items = [];

 
        var da = {};
         
        da['False Positives Calls'] = `${report_data ? report_data.false_alarm : '0'}`;
        da['Non - Urgent Calls'] = `${report_data ? report_data.urgency : '0'}`;
        da['Accidental Triggers'] = `${report_data ? report_data.canceled : '0'}`;
        da['Total Real Dispatch'] = `${report_data ? report_data.total_real_dispatch : '0'}`;
        da['Total Dispatch'] = `${report_data ? report_data.total_dispatch : '0'}`;
    

        report_data.data.forEach((ele) => {
            da[ele.category_class ? ele.category_class : 'Uncategorized'] = `${ele.total}`;
        })

        items.push(da);
       
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        const csv = [
        header.join(','),
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')


        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'export.csv');

        
    }




    return (<>


        <div style={{
            display: 'flex',
            marginTop: 50,
            flex: 1,
            flexDirection: 'column',
            // borderTop: '1px solid #E0E0E0',
            backgroundColor: 'rgb(247, 247, 254)',
            height: '100%',
            width: '100%',

        }}>

            <div className="d-flex justify-content-between" style={{
                backgroundColor: '#FFF',
                paddingLeft: 20,
                paddingRight: 20,
                height: 50,
            }}>
                <span className="text-dark font-size-lg font-weight-bold" style={{
                    fontSize: 20,
                }}>
                    {/* {incident_meta ? incident_meta.total_items : '...'}  */}
                    Reports</span>

                <div>


                {/* <div class="d-flex align-items-center flex-wrap">
               

              
                    <Dropdown 
                     alignRight>
                        <Dropdown.Toggle
                            as={QuickActionsDropdownToggle}
                            id="dropdown-toggle-quick-actions-subheader"
                        >
                            Drop down
                        </Dropdown.Toggle>

                        

                        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                            <Incident_Class_Drop />
                        </Dropdown.Menu>
                    </Dropdown>


                    <Dropdown 
                     alignRight>
                        <Dropdown.Toggle
                            as={QuickActionsDropdownToggle2}
                            id="dropdown-toggle-quick-actions-subheader"
                        >
                            Drop down
                        </Dropdown.Toggle>

                        

                        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                            <Filter_Class_Drop />
                        </Dropdown.Menu>
                    </Dropdown>

                 
                
                    
                </div> */}


                    
                </div>

            </div>


            <div class="row" style = {{marginTop: 20, paddingLeft: 20, paddingRight: 20}}>
                <div class="col-lg-4" 
                // style = {{height: 450}}
                >
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <div class="card-title font-weight-bolder">
                            <div class="card-label  font-weight-bolder text-dark">
                            Total Security Dispatch
                                <div class="font-size-sm text-muted mt-2">
                                    {/* 230 */}
                                </div>
                            </div>
                        </div>
                        <div class="card-toolbar">
                            <div class="dropdown dropdown-inline">
                                
                                {
                                    report_data 
                                    
                                    &&
                                    
                                    <a href="javascript:;" onClick={() => exportToCsv2()} class="btn btn-bg-white btn-light btn-hover-primary btn-icon">
                                        <i class="flaticon-download-1 icon-md"></i>
                                    </a>
                                }
                               
                            
                            </div>
                        </div>
                    </div>

                    <div class="card-body d-flex flex-column" style={{position: "relative", marginBottom: 0}}>


                    <div id="kt_mixed_widget_14_chart" style={{height: "200px"}}></div>
    
                  
                        <div class="mt-10">

                      
                        {
                            report_data && chunkArray(report_data.data, 2).map(
                                chunk =>
                                  <div class="row row-paddingless mb-10">
                                    {chunk.map((el, index) =>
                                      <div class="col" key = {index}>
                                        <div class="d-flex align-items-center mr-2">
                                            <div class={`symbol symbol-45 ${TAB_ANAL_VIEW[index] ? TAB_ANAL_VIEW[index].textS : 'symbol-light-info'} mr-4 flex-shrink-0`}>
                                                <div class="symbol-label">
                                
                                                <span className={`svg-icon svg-icon-lg ${TAB_ANAL_VIEW[index] ? TAB_ANAL_VIEW[index].symbol : 'symbol-light-info'}`}>
                                                    <SVG
                                                        src={toAbsoluteUrl(TAB_ANAL_VIEW[index] ? TAB_ANAL_VIEW[index].url : '/media/svg/icons/Home/Library.svg')}
                                                        className="svg-icon svg-icon-lg"
                                                    ></SVG>
                                                </span>

                                                </div>
                                            </div>
                                    
                                            <div>
                                                <div class="font-size-h4 text-dark-75 font-weight-bolder">{el.total}</div>
                                                <div class="font-size-sm text-muted font-weight-bold mt-1">{el.category_class ? el.category_class : 'Uncategorized'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                  </div>
                              )
                        }

                            {/* <div class="row row-paddingless mb-10">
                                <div class="col-sm">
                                    <div class="d-flex align-items-center mr-2">
                                        <div class="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0">
                                            <div class="symbol-label">
                             
                                            <span className="svg-icon svg-icon-lg svg-icon-info">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                                                    className="svg-icon svg-icon-lg"
                                                ></SVG>
                                            </span>

                                            </div>
                                        </div>
                                
                                        <div>
                                            <div class="font-size-h4 text-dark-75 font-weight-bolder">12</div>
                                            <div class="font-size-sm text-muted font-weight-bold mt-1">Total L1 / L2</div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="col-sm">
                                    <div class="d-flex align-items-center mr-2">
                                        <div class="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                            <div class="symbol-label">
                                            
                                                <span className="svg-icon svg-icon-lg svg-icon-danger">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                                                        className="svg-icon svg-icon-lg"
                                                    ></SVG>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="font-size-h4 text-dark-75 font-weight-bolder">9</div>
                                            <div class="font-size-sm text-muted font-weight-bold mt-1">Total L3 / L4</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm">
                                    <div class="d-flex align-items-center mr-2">
                                        <div class="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                            <div class="symbol-label">
                                            
                                                <span className="svg-icon svg-icon-lg svg-icon-danger">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                                                        className="svg-icon svg-icon-lg"
                                                    ></SVG>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="font-size-h4 text-dark-75 font-weight-bolder">9</div>
                                            <div class="font-size-sm text-muted font-weight-bold mt-1">Total L3 / L4</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm">
                                    <div class="d-flex align-items-center mr-2">
                                        <div class="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                            <div class="symbol-label">
                                            
                                                <span className="svg-icon svg-icon-lg svg-icon-danger">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                                                        className="svg-icon svg-icon-lg"
                                                    ></SVG>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="font-size-h4 text-dark-75 font-weight-bolder">9</div>
                                            <div class="font-size-sm text-muted font-weight-bold mt-1">Total L3 / L4</div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div class="row row-paddingless">
                                <div class="col">
                                    <div class="d-flex align-items-center mr-2">
                                        <div class="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                                            <div class="symbol-label">
                                                
                                                <span className="svg-icon svg-icon-lg svg-icon-success">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                                                        className="svg-icon svg-icon-lg"
                                                    ></SVG>
                                                </span>

                                            </div>
                                        </div>

                                        <div>
                                            <div class="font-size-h4 text-dark-75 font-weight-bolder">6</div>
                                            <div class="font-size-sm text-muted font-weight-bold mt-1">Total L5</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="d-flex align-items-center mr-2">

                                        <div class="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0">
                                            <div class="symbol-label">
                                          
                                                <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                                                        className="svg-icon svg-icon-lg"
                                                    ></SVG>
                                                </span>

                                            </div>
                                        </div>

                                        <div>
                                            <div class="font-size-h4 text-dark-75 font-weight-bolder">16</div>
                                            <div class="font-size-sm text-muted font-weight-bold mt-1">Total Dispatch</div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
	        </div>
 
 
           <div className="row col-lg-8">
           <div class="col-lg-6">
            

                <StatsWidget11 
                    key_id = {2}
                    name = {"False-Positives Calls"}
                    number = {`${report_data ? report_data.false_alarm : '...'}`}
                />
            </div>

            <div class="col-lg-6">
                <StatsWidget11 
                    key_id = {3}
                    name = {"Non - Urgent Calls"}
                    number = {`${report_data ? report_data.urgency : '...'}`}
                />
            </div>

            <div class="col-lg-6">
               
                <StatsWidget11 
                    key_id = {4}
                    name = {"Accidental Triggers"}
                    number = {`${report_data ? report_data.canceled : '...'}`}
                />
            </div>

            <div class="col-lg-6">
                <StatsWidget11 
                    key_id = {6}
                    name = {"Total Real Dispatch"}
                    number = {`${report_data ? report_data.total_real_dispatch : '...'}`}
                />
            </div>


           </div>
           


        </div>




            <div style={{
                backgroundColor: '#FFF',
                padding: 20,
                height: 50,
                marginTop: 20,
                flex: 1,
            }}>




            {/* <div>
                <div style = {{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>



                    <Dropdown 
                     alignRight>
                        <Dropdown.Toggle
                            as={QuickActionsDropdownToggle2}
                            id="dropdown-toggle-quick-actions-subheader"
                        >
                            Drop down
                        </Dropdown.Toggle>

                        

                        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                            <Filter_Class_Drop incident_class = {incident_class} />
                        </Dropdown.Menu>
                    </Dropdown>


                        {
                            entities &&
                            <a href="javascript:;" onClick={() => exportToCsv(entities)} class="btn btn-bg-white btn-light btn-hover-primary btn-icon mr-3 my-2 my-lg-0">
                                <i class="flaticon-download-1 icon-md"></i>
                            </a>
                        }

                    </div>
                </div> */}

                <div ref = {tabRef} >
                    <CustomersFilter incident_class = {incident_class} exportToCsv = {exportToCsv} entities = {entities}/>
                    <Table/>
                </div>       
            </div>


            {/*  */}


        </div>





    </>
    );
}



function getChartOptions(layoutProps, height, series, totals) {
    var options = {
        series: totals,
        chart: {
            height: 250,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                margin: 0,
                size: "35%"
                },
                dataLabels: {
                showOn: "always",
                name: {
                    show: true,
                    fontWeight: "600",
                },
                value: {
                    color: layoutProps.colorsGrayGray700,
                    fontSize: "10px",
                    fontWeight: "600",
                    offsetY: 12,
                    show: true
                },
                },
                track: {
                //   background: layoutProps.colorsThemeLightSuccess,
                strokeWidth: '100%'
                }
            }
        },
        stroke: {
            lineCap: "round",
        },
        labels: series,
    };
    return options;
  }